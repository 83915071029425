import React, {Component} from 'react'

import {connect} from 'react-redux'
import {confirmEntityProfile} from 'avoapp-react-common/dist/redux/entityProfiles'

import {isEmpty} from 'lodash'

import Loader from '../components/Loader'
import RedirectComponent from '../components/RedirectComponent'

import logo from '../assets/images/logo.svg'

import '../styles/EntityProfileConfirm.scss'

export class EntityProfileConfirm extends Component {
    componentDidMount() {
        const {
            confirmEntityProfileUID,
            confirmEntityProfileToken,
            confirmEntityProfileStatus
        } = this.props.match.params

        this.props.dispatch(confirmEntityProfile({
            uid: confirmEntityProfileUID,
            token: confirmEntityProfileToken,
            status: confirmEntityProfileStatus
        }))
    }

    render() {
        const {isLoading, entityProfilesErrors} = this.props

        return (
            <div className="entity-profile-confirm-container">  
                <div className="main-container">
                    <div className="header">
                        <img className="logo" src={logo} alt="AvoApp"/>
                        <h1 className="title">Activare cont</h1>
                    </div>
                    <div className="content">
                        {isLoading ? (
                            <LoadingComponent />
                        ) : (
                            !isEmpty(entityProfilesErrors) ? <ErrorComponent /> : <SuccessComponent />
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

class LoadingComponent extends Component {
    render() {
        return (
            <div className='entity-profile-confirm'>
                <p className='activation-message'>
                    Așteaptă salvarea răspunsului tău
                </p>
                <Loader size='large'/>
            </div>
        )
    }

}

class SuccessComponent extends Component {
    render() {
        return (
            <div className='entity-profile-confirm'>
                <p className='activation-message'>
                    Cofirmat cu succes! Vei fi redirecționat la pagina principală.
                </p>
                <RedirectComponent />
            </div>
        )
    }
}

class ErrorComponent extends Component {
    render() {
        return (
            <div className='entity-profile-confirm'>
                <p className='activation-message'>
                    <span>Oups! </span>Pare că linkul de activare a expirat. <br />
                    Am retrimis chiar acum un alt link pe adresa ta de email pentru a putea fi validat.
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.entityProfiles.isLoading,
    entityProfilesErrors: state.entityProfiles.confirmErrors
})

export default connect(mapStateToProps )(EntityProfileConfirm)
