import React, {Component} from 'react'

import '../styles/Input.scss'

export default class Input extends Component {
    render() {
        const {label, type, autoComplete, required, value, onChange, size, fullWidth} = this.props 

        return (
            <div>
                {label && (
                    <label className="bpi-label">
                        {label}
                    </label>
                )}
                <div className="bpi-input-container">
                    <input
                        value={value}
                        onChange={onChange}
                        className={`bpi-input ${size || 'medium'} ${fullWidth ? 'full-width' : ''}`}
                        autoComplete={autoComplete || 'off'}
                        required={required || false}
                        type={type || 'text'}
                    />
                </div>
            </div>
        )
    }
}
