import React, {useEffect} from 'react'
import {connect, useDispatch} from 'react-redux'

import {isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import Navbar from '../components/Navbar'
import Loader from '../components/Loader'
import PDFViewer from '../components/PDFViewer/index'

import '../styles/NotificationDetails.scss'
import NotificationErrorComponent from '../components/NotificationErrorComponent'
import RedirectComponent from '../components/RedirectComponent'

function NotificationDetails(props) {
    const {notification, errors, isLoading} = props

    const dispatch = useDispatch()

    const _fetchPDF = () => {
        const {notificationID} = props.match.params
        dispatch(RESOURCES.bpiNotifications.retrieve(notificationID))
    }

    useEffect(() => {
        _fetchPDF()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='notification-details'>
            <Navbar />

            <div className='content-container'>
                {!isEmpty(notification) && !isLoading ? (
                    <PDFViewer
                        document={notification.data.document.file_url}
                        keyword={notification.source.vat_number}
                        page={notification.data.page_number_start - 1}
                    />
                ) : !isLoading && !isEmpty(errors) ? (
                    <>
                        <NotificationErrorComponent error={errors} />
                        <RedirectComponent />
                    </>
                ) : isLoading ? (
                    <Loader />
                ) : null}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const notification = state.bpiNotifications.currentNotification

    return {
        notification: notification,
        isLoading: state.bpiNotifications.isLoading,
        errors: state.bpiNotifications.errors
    }
}

export default connect(mapStateToProps)(NotificationDetails)
