import React, {useState} from 'react'
import {Viewer, Worker} from '@react-pdf-viewer/core'
import {SearchIcon} from '@react-pdf-viewer/search'
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'

import {filter} from 'lodash'

import {themeTypes} from '../../utils'

import ToolbarComponent from './ToolbarComponent'
import SearchComponent from './SearchComponent'

export default function PDFViewer(props) {
    const {document, page, keyword} = props
    const [theme, setTheme] = useState(localStorage.getItem('theme') || themeTypes.DARK)
    const {innerWidth: windowWidth} = window

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        toolbarPlugin: {searchPlugin: {keyword: keyword}},
        renderToolbar: (Toolbar) => <ToolbarComponent toolbar={Toolbar} />,
        sidebarTabs: (defaultTabs) =>
            [
                {
                    title: 'Search',
                    icon: <SearchIcon />,
                    content: (
                        <SearchComponent
                            searchPluginInstance={
						        defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance
						    }
                            defaultLayoutPluginInstance={defaultLayoutPluginInstance}
                            initialKeyword={keyword}
                            theme={theme}
                        />
                    )
                }
            ].concat(filter(defaultTabs, ['title', 'Thumbnail']))
    })

    const handleSwitchTheme = (theme) => {
        setTheme(theme)
        localStorage.setItem('theme', theme)
    }

    const getScale = () => (windowWidth >= 1000 ? 1.5 : windowWidth >= 770 ? 1 : windowWidth >= 450 ? 0.6 : 0.5)

    return (
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js'>
            <div style={{height: '100%'}}>
                <Viewer
                    fileUrl={document}
                    plugins={[defaultLayoutPluginInstance]}
                    defaultScale={getScale()}
                    initialPage={page}
                    theme={theme}
                    onSwitchTheme={handleSwitchTheme}
                />
            </div>
        </Worker>
    )
}
