import React, {Component} from 'react'

import '../styles/ErrorComponent.scss'

export default class ErrorComponent extends Component {
    render() {
        const {error} = this.props
        return (
            <div className='error-div'>
                <p className='error-text'>
                    {error}
                </p>
            </div>
        )
    }
}
