import React, {Component} from 'react'

import '../styles/Navbar.scss'

import logoLight from '../assets/images/logo.svg'

export default class Navbar extends Component {
    render() {
        return (
            <div id='navbar'>
                <div className='container mx-auto'>
                    <div className='logo-container'>
                        <img src={logoLight} alt='Monitor BPI logo' className='logo' />
                    </div>
                </div>
            </div>
        )
    }
}
