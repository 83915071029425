import React, {useState} from 'react'
import {MinimalButton, Spinner, TextBox} from '@react-pdf-viewer/core'
import {NextIcon, PreviousIcon} from '@react-pdf-viewer/search'

import '../../styles/SearchComponent.scss'

const SearchStatus = {
    SEARCHING: 'SEARCHING',
    FOUND_RESULTS: 'FOUND_RESULTS',
    NOT_SEARCHABLE: 'NOT_SEARCHABLE'
}

const SearchComponent = (props) => {
    const {searchPluginInstance, theme} = props

    const [searchStatus, setSearchStatus] = useState(SearchStatus.NOT_SEARCHABLE)
    const [matches, setMatches] = useState([])

    const {Search} = searchPluginInstance

    const renderMatchSample = (match) => {
        const wordsBefore = match.pageText.substr(match.startIndex - 20, 20)
        let words = wordsBefore.split(' ')
        words.shift()
        const begin = words.length === 0 ? wordsBefore : words.join(' ')
        const wordsAfter = match.pageText.substr(match.endIndex, 60)
        words = wordsAfter.split(' ')
        words.pop()
        const end = words.length === 0 ? wordsAfter : words.join(' ')

        return (
            <div>
                {begin}
                <span className='highlighted'>{match.pageText.substring(match.startIndex, match.endIndex)}</span>
                {end}
            </div>
        )
    }

    return (
        <Search>
            {(renderSearchProps) => {
			    const {currentMatch, keyword, setKeyword, jumpToMatch, jumpToNextMatch, jumpToPreviousMatch, search} =
					renderSearchProps

			    const handleSearchKeyDown = (e) => {
			        if (e.key === 'Enter' && keyword) {
			            setSearchStatus(SearchStatus.SEARCHING)
			            search().then((matches) => {
			                setSearchStatus(SearchStatus.FOUND_RESULTS)
			                setMatches(matches)
			            })
			        }
			    }

			    return (
    <div className={`search-component-container ${theme}`}>
        <div className='input-container'>
            <div className='input-container-relative'>
                <TextBox
                    placeholder='Caută'
                    value={keyword}
                    onChange={setKeyword}
                    onKeyDown={handleSearchKeyDown}
                />
                {searchStatus === SearchStatus.SEARCHING && (
                <div className='loader-container'>
                    <Spinner size='1.5rem' />
                </div>
                )}
            </div>
        </div>
        {searchStatus === SearchStatus.FOUND_RESULTS && (
            <>
                {matches.length === 0 && 'Niciun rezultat'}
                {matches.length > 0 && (
                    <>
                        <div className='results-found-container'>
                            <div className='results-found'>
                                {matches.length} {matches.length === 1 ? 'rezultat' : 'rezultate'}
                            </div>
                            <div className='controls-previous'>
                                <MinimalButton onClick={jumpToPreviousMatch}>
                                    <PreviousIcon />
                                </MinimalButton>
                            </div>
                            <MinimalButton onClick={jumpToNextMatch}>
                                <NextIcon />
                            </MinimalButton>
                        </div>
                        <div className='matches-container'>
                            {matches.map((match, index) => (
                                <div key={index} className='match-container'>
                                    <div className='match-info'>
                                        <div>#{index + 1}</div>
                                        <div className='match-page'>Pagina {match.pageIndex + 1}</div>
                                    </div>
                                    <div
                                        className={`match-preview ${
													        currentMatch === index + 1 && 'isCurrent'
													    }`}
                                        onClick={() => jumpToMatch(index + 1)}
                                    >
                                        {renderMatchSample(match)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
						        )}
            </>
        )}
    </div>
			    )
            }}
        </Search>
    )
}

export default SearchComponent
