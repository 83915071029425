import React, {Component} from 'react'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import '../styles/TermsAndConditions.scss'

class TermsAndConditions extends Component {
    componentDidMount() {
        this.props.dispatch(RESOURCES.terms.list())
    }
    
    render() {
        const {latestTerms, isLoadingTerms} = this.props

        return (
            <div className="terms-and-conditions-container">
                {!isLoadingTerms ? (
                    <div 
                        className="prose prose-lg"
                        dangerouslySetInnerHTML={{__html: latestTerms.content}} 
                    />
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        )
    }
}

const mapStateToProps = function(state) {
    return {
        latestTerms: state.terms.latestTerms,
        isLoadingTerms: state.terms.isLoading
    }
}

export default connect(mapStateToProps)(TermsAndConditions)
