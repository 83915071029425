export const env = process.env.NODE_ENV

export const environmentTypes = {
    DEV: 'development',
    LOCAL: 'local',
    PROD: 'production'
}

export const themeTypes = {
    DARK: 'dark',
    LIGHT: 'light'
}
