import React, {useEffect, useState} from 'react'

import {LANDING_URL} from '../settings'

import '../styles/RedirectComponent.scss'

export default function RedirectComponent() {
    const [time, setTime] = useState(5)

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    })

    const tick = () => (time === 1 ? (window.location.href = LANDING_URL) : setTime(time - 1))

    return (
        <div className='redirect-container'>
            <h2 className='redirect-text'>
                Vei fi redirecționat in{' '}
                <span>{time !== 1 ? `${time.toString().padStart(1, '0')} secunde` : '1 secundă'} </span>
            </h2>
            <a className='redirect-url' href={LANDING_URL}>
                Landing Page Monitor BPI
            </a>
        </div>
    )
}
