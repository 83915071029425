import {toast} from 'react-toastify'
import {put, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'

import {ACTIONS} from 'avoapp-react-common/dist/redux/entityProfiles'

function* handleConfirmEntityProfileSuccess() {
    const message = 'Raspunsul tău a fost salvat'
    toast.success(message)

    yield put(push('/login'))
}

function handleConfirmEntityProfileFail() {
    const message = 'A aparut o eroare'
    toast.error(message)
}

export function* saga() {
    yield takeEvery(ACTIONS.CONFIRM_ENTITY_PROFILE.success, handleConfirmEntityProfileSuccess)
    yield takeEvery(ACTIONS.CONFIRM_ENTITY_PROFILE.fail, handleConfirmEntityProfileFail)
}

