import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {resetPassword} from 'avoapp-react-common/dist/redux/auth'

import Input from '../components/Input'
import Button from '../components/Button'
import ErrorComponent from '../components/ErrorComponent'

import logo from '../assets/images/logo.svg'

import '../styles/ResetPassword.scss'

class ResetPassword extends Component {
    render() {
        const {authErrors, isLoading} = this.props
        const {resetPasswordUID, resetPasswordCode} = this.props.match.params

        return (
            <div className="reset-password-container">  
                <div className="main-container">
                    <div className="header">
                        <img className="logo" src={logo} alt="AvoApp"/>
                        <h1 className="title">Resetare parolă</h1>
                    </div>
                    <div className="m-6 mt-0">
                        {!isEmpty(authErrors) && isEmpty(authErrors.detail) ? (
                            <div className="error-section">
                                {_values(authErrors).map((errorCategories) => 
                                    _values(errorCategories).map((error, index) => 
                                        <ErrorComponent key={index} error={getErrorFromCode(error, 'register')}/>
                                    )
                                )}
                            </div>
                        ) : null}
                        <Formik
                            initialValues={{
                                password: '',
                                newPassword1: '',
                                newPassword2: '',
                                uid: resetPasswordUID,
                                code: resetPasswordCode
                            }}
                            onSubmit={(values) => {
                                console.log('values', values)
                                this.props.dispatch(resetPassword({
                                    new_password1: values.newPassword1,
                                    new_password2: values.newPassword2,
                                    uid: values.uid,
                                    code: values.code
                                         
                                }))
                            }}
                        >
                            {({handleChange, values, handleSubmit}) => (
                                <Form className="space-y-6">
                                    <Input 
                                        label='Parola' 
                                        value={values.newPassword1}
                                        onChange={handleChange('newPassword1')}
                                        autoComplete='password' 
                                        type='password' 
                                        fullWidth
                                    />
                                    <Input 
                                        label='Confirmare parola' 
                                        value={values.newPassword2}
                                        onChange={handleChange('newPassword2')}
                                        autoComplete='password' 
                                        type='password' 
                                        fullWidth
                                    />
                                    <Button 
                                        title='Schimbă parola' 
                                        onClick={handleSubmit} 
                                        disabled={isEmpty(values.newPassword1) || isEmpty(values.newPassword2)} 
                                        loading={isLoading}
                                        fullWidth
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authErrors: state.auth.errors,
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps)(ResetPassword)
