import React, {Component} from 'react'

import '../styles/Button.scss'

export default class Button extends Component {
    render() {
        const {title, disabled, loading, onClick, size, variant, color, fullWidth, className} = this.props

        return (
            <button 
                onClick={onClick}
                className={`bpi-button ${variant || 'contained'} ${size || 'medium'} ${color || 'primary'} ${fullWidth ? 'full-width' : ''} ${className}`}
                disabled={disabled || loading}
            >
                {title} 
            </button>
        )
    }
}
