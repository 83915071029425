import React, {Component} from 'react'

import logo from '../assets/images/logo.svg'

import '../styles/ResetPasswordSuccess.scss'

export default class ResetPasswordSuccess extends Component {
    render() {
        return (
            <div className="reset-password-success-container">  
                <div className="main-container">
                    <div className="header">
                        <img className="logo" src={logo} alt="AvoApp"/>
                        <h1 className="title">Resetare parolă</h1>
                    </div>
                    <div className="content">
                        <div className='reset-password-success'>
                            <p className='reset-password-success-message'>
                                Parola a fost schimbată cu succes. Folosește noua parola pentru a te autentifica în aplicația Monitor BPI pe smartphone-ul tău.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

