import {createStore as createReduxStore, applyMiddleware, combineReducers} from 'redux'

import {createBrowserHistory} from 'history'
import {connectRouter, routerMiddleware} from 'connected-react-router'

import createSagaMiddleware from 'redux-saga'
import {all} from 'redux-saga/effects'

import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {saga as authSaga} from './auth'
import {saga as entityProfilesSaga} from './entityProfiles'

import {api, auth, bpiNotifications, entityProfiles} from 'avoapp-react-common'

import {reducer as appReducer} from './app'
import routerLocations from './router'

import {env, environmentTypes} from '../utils'

const persistConfig = {
    timeout: 0,
    key: 'cache',
    storage: storage,
    whitelist: []
}

export const history = createBrowserHistory()

function* rootSaga() {
    yield all([
        api.saga(),
        authSaga(),
        entityProfilesSaga()
    ])
}

const rootReducer = (history) =>
    combineReducers({
        api: api.reducer,
        app: appReducer,
        auth: auth.reducer,
        bpiNotifications: bpiNotifications.reducer,
        entityProfiles: entityProfiles.reducer,
        router: connectRouter(history),
        routerLocations: routerLocations
    })

const persistedRootReducer = persistReducer(persistConfig, rootReducer(history))

export function createRootStore(initialState = {}) {
    const sagaMiddleware = createSagaMiddleware()

    const store = createReduxStore(
        persistedRootReducer,
        initialState,
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
    )

    sagaMiddleware.run(rootSaga)

    if (env === environmentTypes.DEV) {
        store.subscribe(() => {
            console.log(store.getState())
        })
    }

    return store
}

export const store = createRootStore()
export const persistor = persistStore(store)
