import React, {Component} from 'react'

import {connect} from 'react-redux'
import {activateAccount} from 'avoapp-react-common/dist/redux/auth'

import Loader from '../components/Loader'

import logo from '../assets/images/logo.svg'

import '../styles/AccountActivation.scss'

export class AccountActivation extends Component {
    componentDidMount() {
        const {activationCode} = this.props.match.params

        this.props.dispatch(activateAccount({key: activationCode}))
    }

    render() {
        const {isLoading, accountActivated} = this.props

        return (
            <div className="account-activation-container">  
                <div className="main-container">
                    <div className="header">
                        <img className="logo" src={logo} alt="AvoApp"/>
                        <h1 className="title">Activare cont</h1>
                    </div>
                    <div className="content">
                        {
                            isLoading ? 
                                <LoadingComponent /> : 
                                accountActivated ? <AccountActivated /> : <AccountActivationFail />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class LoadingComponent extends Component {
    render() {
        return (
            <div className='account-activation'>
                <p className='activation-message'>
                    Așteaptă salvarea răspunsului tău
                </p>
                <Loader size='large'/>
            </div>
        )
    }

}

class AccountActivated extends Component {
    render() {
        return (
            <div className='account-activation'>
                <p className='activation-message'>
                    Contul tau a fost confirmat! Poți începe monitorizarea companiilor în buletinul procedurilor de insolvență chiar acum în aplicația smartphone.
                </p>
            </div>
        )
    }
}

class AccountActivationFail extends Component {
    render() {
        return (
            <div className='account-activation'>
                <p className='activation-message'>
                    <span>Oups! </span>Pare că linkul de activare a expirat. <br />
                    Am retrimis chiar acum un alt link pe adresa ta de email pentru a putea fi validat.
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    accountActivated: state.auth.accountActivated,
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps )(AccountActivation)
