import {toast} from 'react-toastify'
import {push} from 'connected-react-router'

import {takeEvery, put} from 'redux-saga/effects'

import {ACTIONS} from 'avoapp-react-common/dist/redux/auth'

function* handleForgotPasswordSuccess() {
    const message = 'Am trimis un link de resetare a parolei pe adresa de email introdusă'
    
    yield put(push('/'))

    toast.success(message)
}

function* handleResetPasswordSuccess() {
    yield put(push('/reset-password-success'))
}

export function* saga() {
    yield takeEvery(ACTIONS.FORGOT_PASSWORD.success, handleForgotPasswordSuccess)
    yield takeEvery(ACTIONS.RESET_PASSWORD.success, handleResetPasswordSuccess)
}
