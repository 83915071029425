import {Provider} from 'react-redux'
import {history, persistor, store} from './redux/store'
import {PersistGate} from 'redux-persist/integration/react'

import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {ConnectedRouter} from 'connected-react-router'
import {Switch, Route} from 'react-router-dom'

import Home from './pages/Home'

import AccountActivation from './pages/AccountActivation'

import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import ResetPasswordSuccess from './pages/ResetPasswordSuccess'
import TermsAndConditions from './pages/TermsAndConditions'

import NotificationDetails from './pages/NotificationDetails'

import EntityProfileConfirm from './pages/EntityProfileConfirm'

import NotFound from './pages/NotFound'

import './styles/App.scss'

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ToastContainer autoClose={3000} />
                <ConnectedRouter history={history}>
                    <div className='App'>
                        <Switch>
                            <Route path='/' exact component={Home} />
                            <Route exact path='/forgot-password' component={ForgotPassword}/>
                            <Route path="/password-confirmation/:resetPasswordUID/:resetPasswordCode" component={ResetPassword}/>
                            <Route path="/reset-password-success" component={ResetPasswordSuccess}/>
                            <Route path="/account-activation/:activationCode" component={AccountActivation} />
                            <Route exact path='/terms-and-conditions' component={TermsAndConditions}/>
                            <Route
                                path={'/entity-profile-confirmation/:confirmEntityProfileUID/:confirmEntityProfileToken/:confirmEntityProfileStatus'}
                                component={EntityProfileConfirm}
                            />
                            <Route path='/notifications/:notificationID' exact component={NotificationDetails} />
                            <Route component={NotFound} />
                        </Switch>
                    </div>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    )
}

export default App
