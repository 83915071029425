import React, {Component} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {getErrorFromCode} from 'avoapp-react-common/dist/utils'
import {forgotPassword} from 'avoapp-react-common/dist/redux/auth'

import Input from '../components/Input'
import Button from '../components/Button'
import ErrorComponent from '../components/ErrorComponent'

import logo from '../assets/images/logo.svg'

import '../styles/ForgotPassword.scss'

class ForgotPassword extends Component {
    render() {
        const {authErrors, isLoading} = this.props

        return (
            <div className="forgot-password-container">  
                <div className="main-container">
                    <div className="header">
                        <img className="logo" src={logo} alt="AvoApp"/>
                        <h1 className="title">Resetare parolă</h1>
                        <p>Pentru a reseta parola contului tău, introdu adresa de email și iți vom trimite un link de resetare.</p>
                    </div>
                    <div className="m-6 mt-3">
                        {!isEmpty(authErrors) && isEmpty(authErrors.detail) ? (
                            <div className="error-section">
                                {_values(authErrors).map((errorCategories) => 
                                    _values(errorCategories).map((error, index) => 
                                        <ErrorComponent key={index} error={getErrorFromCode(error, 'register')}/>
                                    )
                                )}
                            </div>
                        ) : null}
                        <Formik
                            initialValues={{email: ''}}
                            onSubmit={(values) => this.props.dispatch(forgotPassword(values))}
                        >
                            {({handleChange, values, handleSubmit}) => (
                                <Form className="space-y-6">
                                    <Input 
                                        label='Adresa de email' 
                                        type='email' 
                                        autoComplete='email' 
                                        fullWidth
                                        value={values.email}
                                        onChange={handleChange('email')}
                                    />
                                    <Button title='Trimite link de resetare' disabled={isEmpty(values.email)} onClick={handleSubmit} loading={isLoading} fullWidth/>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authErrors: state.auth.errors,
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps)(ForgotPassword)
