import React from 'react'

import '../styles/NotificationErrorComponent.scss'

export default function NotificationErrorComponent({error}) {
    const renderError = (error) => {
        switch (error.code) {
            case 'not_found':
                return 'Această notificare nu există!'

            default:
                return 'A apărut o eroare!'
        }
    }

    return (
        <div className='error-container'>
            <h1 className='error-text'>{renderError(error)}</h1>
        </div>
    )
}
