import React from 'react'
import RedirectComponent from '../components/RedirectComponent'

import logo from '../assets/images/logo.svg'

import '../styles/Home.scss'

export default function Home() {
    return (
        <div className='home-container'>
            <img src={logo} alt='Monitor BPI' />
            <RedirectComponent />
        </div>
    )
}
