import React from 'react'

import '../styles/Loader.scss'

export default function Loader() {
    return (
        <div className='loader-container'>
            <div className='loader' />
        </div>
    )
}
